import React from "react";
import TestimonialSection from "../../components/TestimonialSection";

const TestimonialBlock = ({ heading_h3, testimonial_block_container }) => {
  return heading_h3 && testimonial_block_container ? (
    <TestimonialSection
      title={heading_h3}
      slide={testimonial_block_container.slide || undefined}
      style={{ paddingTop: "131px", paddingBottom: "189px" }}
    />
  ) : null;
};

export default TestimonialBlock;

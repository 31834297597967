import React from "react";
import { Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const ContactBlock = ({
  headline_h3,
  contact_block,
  facebook_link,
  contact_column_1,
  contact_column_2,
}) => {
  return (
    <div className="kontakt-container">
      <div className="footer-contact">
        {headline_h3 ? <h2 className="footer-contact-title">{headline_h3}</h2> : null}
        <div className="container">
          <Row>
            {contact_block ? (
              <>
                {contact_block.map(detail => (
                  <Col md={6} key={detail.label}>
                    <h3 className="title-contact">{detail.label} </h3>

                    {detail.link && detail.open_new_tab === true ? (
                      <a
                        className="contact-data"
                        target="_blank"
                        rel="noreferrer"
                        href={detail.link}
                      >
                        {detail.value}
                      </a>
                    ) : (
                      <p className="contact-data">{detail.value}</p>
                    )}
                  </Col>
                ))}
              </>
            ) : (
              ""
            )}

            <Col md={6}>
              <p className="facebook-icon">
                {facebook_link && (
                  <a target="_blank" rel="noreferrer" href={facebook_link}>
                    f
                  </a>
                )}
              </p>
            </Col>
          </Row>
          <div className="contact-wrap">
            <Row>
              <Col md={6}>
                <h4>{contact_column_1.heading_h4}</h4>
                <div className="text">
                  <p>{ReactHtmlParser(contact_column_1.content)}</p>
                </div>
              </Col>
              <Col md={6}>
                <h4>{contact_column_2.heading_h4}</h4>
                <div className="text">
                  <p>{ReactHtmlParser(contact_column_2.content)}</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBlock;

import React from "react";
import CoverImage from "../../components/coverImageTitle";

const HeroTitle = ({ image, title }) => {
  return (
    (image && title) ? (
      <div className="blog-wrap-hero">
        <CoverImage
          fluid={image.localFile.childImageSharp.fluid}
          title={title}
        />
      </div>
    ) : null
  );
};

export default HeroTitle;

import React from 'react';
import SliderComponent from '../../components/slider'
 
 const SliderBock = ({slider_section}) => {
   return (
     slider_section ? <SliderComponent
       static_slider_section_title={slider_section.static_slider_section_title}
       static_image_slider={slider_section.static_image_slider}
       fluid={
         slider_section.static_slider_background_image.localFile.childImageSharp.fluid
       }
     /> : null
   );
 }
 
 export default SliderBock
 
import React from "react";
import ImagesGrid from "../../components/imagesGrid";

const Grid_3x_with_Images_Container = ({ grid_3x_with_images_container }) => {
  return grid_3x_with_images_container ? (
    <ImagesGrid
      firstImageGrid={
        grid_3x_with_images_container.image_1.localFile.childImageSharp.fluid
      }
      secondImageGrid={
        grid_3x_with_images_container.image_2.localFile.childImageSharp.fluid
      }
      thirdImageGrid={
        grid_3x_with_images_container.image_3.localFile.childImageSharp.fluid
      }
    />
  ) : null;
};

export default Grid_3x_with_Images_Container;

import React from "react";
import ReactHtmlParser from "react-html-parser";
import Img from "gatsby-image";

const FeatureBlockDouble = ({ feature_list, title }) => {
  return feature_list && title ? (
    <div className="feature-section feature-dynamic">
      <h2 className="title">{ReactHtmlParser(title)}</h2>
      <div className="container">
        <div className="row feature-grid">
          {feature_list.map((item, index) => {
            return (
              <div key={item.title} className="col-md-6 p-0">
                <div className="feature">
                  {item.image && (
                    <Img fluid={item.image.localFile.childImageSharp.fluid} />
                  )}
                  <h3 className="feature-title">
                    {ReactHtmlParser(item.title)}
                  </h3>
                  <p className="feature-text">{ReactHtmlParser(item.text)}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default FeatureBlockDouble;

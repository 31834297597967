import React from "react";
import CoverImage from "../../components/coverImage";

const Hero = ({ image }) => {
  return image ? (
    <div className="blog-wrap-hero">
      <CoverImage fluid={image.localFile.childImageSharp.fluid} />
    </div>
  ) : null
};

export default Hero;
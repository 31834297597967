
import React from "react";
import { v4 as uuid } from 'uuid';
import { graphql } from "gatsby";

import Hero from '../src/layouts/Hero';
import HeroTitle from '../src/layouts/HeroTitle';
import Column_1x_text_h2 from '../src/layouts/Column_1x_text_h2';
import Column_1x_text_h3 from '../src/layouts/Column_1x_text_h3';
import Column_2x_text_h2 from '../src/layouts/Column_2x_text_h2';
import Grid_2x_with_Logos_Container from '../src/layouts/Grid_2x_with_Logos_Container';
import Grid_3x_with_Images_Container from '../src/layouts/Grid_3x_with_Images_Container';
import CTABlock from '../src/layouts/CTABlock';
import FeatureBlock from '../src/layouts/FeatureBlock';
import FeatureBlockDouble from '../src/layouts/FeatureBlockDouble';
import TestimonialBlock from '../src/layouts/TestimonialBlock';
import SliderBock from '../src/layouts/SliderBock';
import PartnerBlock from '../src/layouts/PartnerBlock';
import ContactBlock from '../src/layouts/ContactBlock';
import ContactForm from '../src/layouts/ContactForm';

const Page = ({ pageContext }) => {
  const {
    page: { acf },
  } = pageContext
  
 
  const layouts = acf.layouts_page || [];
  return (
     <>
      {
        layouts.map((layout, index) => {
          
              if (layout.__typename === 'WordPressAcf_hero_image') {
                  return <Hero {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_hero_image_title') {
                  return <HeroTitle {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_column_1x_text_h2') {
                  return <Column_1x_text_h2 {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_column_1x_text_h3') {
                  return <Column_1x_text_h3 {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_column_2x_text_h2') {
                  return <Column_2x_text_h2 {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_grid_2x_with_logos_container') {
                  return <Grid_2x_with_Logos_Container {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_grid_3x_with_images_container') {
                  return <Grid_3x_with_Images_Container {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_cta_block') {
                  return <CTABlock {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_feature_block') {
                  return <FeatureBlock {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_feature_block_double') {
                  return <FeatureBlockDouble {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_testimonial_block') {
                  return <TestimonialBlock {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_slider_block') {
                  return <SliderBock {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_partner_block') {
                  return <PartnerBlock {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_contact_block') {
                  return <ContactBlock {...layout} key={uuid()} />;
              }
            

              if (layout.__typename === 'WordPressAcf_contact_form') {
                  return <ContactForm {...layout} key={uuid()} />;
              }
            
        })
      }
    </>
  )
}


export const query = graphql `query($lang: String, $slug: String) { wordpressPage(slug: { eq: $slug }, wpml_current_locale: { eq: $lang }) { title path yoast_head }}`

export default Page
  
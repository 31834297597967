import React from "react";
import PartnerSection from "../../components/parternsSection";

const PartnerBlock = ({ partner_section }) => {
  return partner_section ? (
    <PartnerSection
      partner_title={partner_section.partner_title}
      partner_subtitle={partner_section.partner_subtitle}
      partner_quote={partner_section.partner_quote}
      partner_description={partner_section.partner_description}
      bottom_text={partner_section.bottom_text}
      partner_logo={
        partner_section.partner_logo.localFile.childImageSharp.fixed
      }
    />
  ) : null;
};

export default PartnerBlock;

import React, { useState, useEffect } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import Fade from "react-reveal/Fade";

const ContactForm = ({
  color_template,
  contact_form_title,
  description,
  message_placeholder,
  consent_hint,
  consent_checkbox_label,
  send_success_message,
  send_button,
  lang = 'de_DE',
  dropdown_label,
  dropdown_validation_error_message,
  time_window_dropdown_options,
  dropdown_placeholder,
}) => {
  // const [timeWindow, setTimeWindow] = useState(`${dropdown_placeholder}`);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setisSending] = useState(false);
  const [show, setShow] = useState(false);
  // const [showDanger, setshowDanger] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const updateValidationStrings = () => {
    const requiredString =
      lang === "de_DE"
        ? "Bitte füllen Sie dieses Feld aus"
        : "Please fill out this field";
    if (!name || name === "") {
      document
        .querySelector("#formGroupName")
        .setCustomValidity(requiredString);
    } else {
      document.querySelector("#formGroupName").setCustomValidity("");
    }
    if (!email || email === "") {
      document
        .querySelector("#formGroupEmail")
        .setCustomValidity(requiredString);
    } else {
      document.querySelector("#formGroupEmail").setCustomValidity("");
    }
    if (!message || message === "") {
      document
        .querySelector("#formGroupNachricht")
        .setCustomValidity(requiredString);
    } else {
      document.querySelector("#formGroupNachricht").setCustomValidity("");
    }

    if (!document.getElementById("customCheck1").checked) {
      document.querySelector("#customCheck1").setCustomValidity(requiredString);
    } else {
      document.querySelector("#customCheck1").setCustomValidity("");
    }
  };

  const handleSendButton = event => {
    const form = event.currentTarget;
    form.checkValidity();
    // if (form.checkValidity() && timeWindow !== dropdown_placeholder)
    //  {
    event.preventDefault();
    setisSending(true);
    const formData = new FormData();
    formData.append("your-name", name);
    formData.append("your-email", email);
    formData.append("your-message", message);
    // formData.append('your-hours', timeWindow);
    const url =
      "http://api.helmut.de/wp-json/contact-form-7/v1/contact-forms/101/feedback";
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }
        return response.json();
      })

      .then(data => {
        // setshowDanger(false);
        setShow(true);
        setName("");
        setEmail("");
        setMessage("");
        // setTimeWindow(`${dropdown_placeholder}`);
        setisSending(false);
        setisChecked(false);
      })
      .catch(error => {
        // setshowDanger(false);
        setisSending(false);
        setisChecked(false);
        setName("");
        setEmail("");
        setMessage("");
        // setTimeWindow(`${dropdown_placeholder}`);
      });
    // }
    // else {
    //   event.preventDefault();
    //   setshowDanger(true);
    // }
  };

  useEffect(() => {
    updateValidationStrings();
  }, [name, email, message, isChecked]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setshowDanger(false);
  //   }, 3500);
  // }, [showDanger]);
  return (
    <div className="contact-form-wrapper">
      <div
        className="copy-wrapper-footer"
        style={{ background: color_template }}
      >
        <div className="bg-color">
          <Fade bottom distance="50px">
            <h2 className="titleDataAnimation">{contact_form_title}</h2>
          </Fade>

          <Row>
            {
              <>
                <Col md={6} className="first_column">
                  <p className="textDescription">{description}</p>
                  {/* <p className="dropdown_label">{dropdown_label}</p>
                    <Alert show={showDanger} variant="danger" onClose={() => setshowDanger(false)} dismissible style={{ marginTop: '10px' }}>
                      <p>{dropdown_validation_error_message}</p>

                    </Alert>
                    <DropdownButton data-display="static" flip={false} drop="down" onSelect={setTimeWindow} id="dropdown-basic-button" title={timeWindow} className="dropdown">
                      {time_window_dropdown_options.map((time) => (
                        <Dropdown.Item data-display="static" key={time.title} eventKey={time.title} className="dropdown">{time.title}</Dropdown.Item>
                      ))}

                    </DropdownButton> */}
                </Col>
                <Col md={6} className="second_column">
                  <Form onSubmit={handleSendButton}>
                    <Form.Group controlId="formGroupName">
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupEmail">
                      <Form.Control
                        type="email"
                        placeholder="e-Mail"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupNachricht">
                      <Form.Control
                        as="textarea"
                        rows="1"
                        placeholder={`${message_placeholder}`}
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <p className="personenbezogenerDaten">{consent_hint} </p>
                    <div className="custom-control custom-checkbox">
                      <input
                        required
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        onChange={() => setisChecked(!isChecked)}
                        checked={isChecked}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        {consent_checkbox_label}
                      </label>
                    </div>

                    <Alert
                      show={show}
                      variant="success"
                      onClose={() => setShow(false)}
                      dismissible
                    >
                      <p>{send_success_message}</p>
                    </Alert>

                    <button
                      type="submit"
                      className="btn submit btn-dynamic-send"
                      disabled={isSending}
                    >
                      {`${send_button}`}
                    </button>
                  </Form>
                </Col>
              </>
            }
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

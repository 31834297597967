import React from "react";

const CTABlock = ({ section_with_cta_container }) => {
  return section_with_cta_container ? (
    <div className="section-with-cta cta-dynamic">
      <h2>{section_with_cta_container.section_title}</h2>
      <p>{section_with_cta_container.section_subtitle}</p>
      <a className="btn btn-primary" href={section_with_cta_container.link}>
        {section_with_cta_container.button_label}
      </a>
    </div>
  ) : null;
};

export default CTABlock;

import React from "react";
import ReactHtmlParser from "react-html-parser";

const Column_2x_text_h2 = ({ headline_h2, column_1 }) => {
  return (
    (headline_h2 && column_1) ? (
      <div className="article-with-image-and-cards">
        <div className="content-warpper">
          <div className="text-section">
            <h2>{ReactHtmlParser(headline_h2)}</h2>
            <div className="description">
              <p>{ReactHtmlParser(column_1)}</p>
            </div>
          </div>
        </div>
      </div>
    ) : null
  );
};

export default Column_2x_text_h2;

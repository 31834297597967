import React from "react";
import ParentsLogo from "../../components/parentsLogoGrid";

const Grid_2x_with_Logos_Container = ({ grid_2x_with_logos_container }) => {
  return grid_2x_with_logos_container ? (
    <ParentsLogo
      subtitle_logo_grid={grid_2x_with_logos_container.section_subtitle}
      title_logo_grid={grid_2x_with_logos_container.section_title}
      imagesLogoGrid={grid_2x_with_logos_container.logos}
      button_label={grid_2x_with_logos_container.button_label}
    />
  ) : null;
};

export default Grid_2x_with_Logos_Container;

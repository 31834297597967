import React from "react";
import ReactHtmlParser from "react-html-parser";

const Column_1x_text_h3 = ({ headline_h3, text_h3 }) => {
  return (
    headline_h3 &&
    text_h3 ? (
      <div className="article-with-image-and-cards">
        <div className="content-warpper">
          <div className="text-section">
            <h3 className="text-h3">{ReactHtmlParser(headline_h3)}</h3>
            {text_h3.map((item, index) => (
              <div className="text-wrap" key={index}>
                <h4>{ReactHtmlParser(item.headline_h4)}</h4>
                <div className="text">
                  <p>{ReactHtmlParser(item.column_1x)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    ) : null
  );
};

export default Column_1x_text_h3;
